<template>
  <common-warpper title="公司动态" en="COMPANY NEWS" :imgSrc="banner">
    <div class="searchInfo" v-show="!!queryParams.title">
      已筛选标题：<el-tag closable @close="clearSearch">{{
        queryParams.title
      }}</el-tag>
    </div>
    <div class="news-warpper">
      <ul class="category">
        <li
          v-for="(item, i) in categories"
          :key="i"
          :class="{ active: active === i }"
          @click="changeType(i)"
        >
          {{ item.lable }}
        </li>
      </ul>
      <div style="flex: 1">
        <ul class="list">
          <li
            v-for="(item, i) in list"
            :key="i"
            @click="$router.push(`/newsDetail?id=${item.id}`)"
          >
            <img class="image" :src="item.icon" />
            <div class="info">
              <div>
                <div class="title">
                  <p>
                    {{ item.title }}
                  </p>
                </div>
                <div class="text">
                  {{ item.summary }}
                </div>
              </div>
              <span>{{ item.createTime }}</span>
            </div>
          </li>
        </ul>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </common-warpper>
</template>
<script>
import CommonWarpper from "@/components/CommonWarpper.vue";
import banner from "@/assets/images/banner/news.jpg";
import { pageByCode } from "@/api/index";

export default {
  name: "News",
  components: {
    CommonWarpper,
  },
  data() {
    return {
      banner,
      list: [],
      categories: [
        { lable: "公司动态", value: "news" },
        { lable: "公司公告", value: "notice" },
      ],
      active: 0,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        code: undefined,
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.queryParams.title = val.query.title || "";
        this.getList();
      },
    },
  },
  created() {},
  methods: {
    clearSearch() {
      this.$router.replace(`/news`);
    },
    searchList() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    changeType(i) {
      if (this.active === i) return;
      this.active = i;
      this.searchList();
    },
    getList() {
      const params = {
        ...this.queryParams,
        code: this.categories[this.active].value,
      };
      pageByCode(params).then((res) => {
        this.list = res.data && res.data.records;
        this.total = res.data && res.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.news-warpper {
  display: flex;
  padding: 0 10px;
  .category {
    padding-top: 30px;
    width: 170px;
    li {
      list-style: disc;
      position: relative;
      color: #999;
      font-size: 14px;
      padding-bottom: 36px;
      cursor: pointer;
      &.active {
        color: $main-color;
        font-size: 18px;
      }
      &::before {
        content: "";
        position: absolute;
        left: -14px;
        top: 15px;
        width: 1px;
        height: 100%;
        background: #d8d9dd;
      }
    }
  }
  .list {
    li {
      display: flex;
      cursor: pointer;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 15px;
      border-bottom: 1px solid #eee;
      &:hover {
        .image {
          transform: scale(1.1);
        }
      }
      .image {
        width: 196px;
        height: 130px;
        margin-right: 25px;
        object-fit: cover;
        border-radius: 2px;
        transition: 0.5s all;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .title {
        display: flex;
        line-height: 30px;
        p {
          font-size: 16px;
          color: #000;
          flex: 1;
          @include clamp(1);
        }
      }
      .text {
        display: none;
        margin-top: 10px;
        line-height: 1.6;
        font-size: 14px;
        color: #666;
        @include clamp(3);
      }
      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
